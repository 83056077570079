<template>
  <v-parallax src="@/assets/images/pages/reset-bg.png" height="100%">
    <v-container fill-height>
      <v-alert type="info" v-if="!validLink">
        <span class="text-h6">Verifying...</span>
      </v-alert>

      <v-row v-if="validLink" align="center" justify="center">
        <v-col align-self="center" cols="12" sm="6">
          <Logo/>
        </v-col>
        
        <v-col cols="12" sm="4">
          <v-card outlined class="pa-3" height="500">
            <v-card-text class="pt-3">
              <h2 class="mb-10">Update your password</h2>

              <v-form ref="passwordForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="passwordForm.password"
                      placeholder="Password"
                      :append-icon="showPassword ? icons.mdiEye : icons.mdiEyeOff"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      :rules="[
                        required('Password'),
                        password('Password'),
                        passwordWithEmail('Password', userInfo.email),
                        validatePasswordWithName(),
                      ]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      dense
                      v-model="passwordForm.repassword"
                      placeholder="Re-enter password"
                      :append-icon="showRePassword ? icons.mdiEye : icons.mdiEyeOff"
                      :type="showRePassword ? 'text' : 'password'"
                      @click:append="showRePassword = !showRePassword"
                      :rules="[
                        required('Re-enter password'),
                        match(passwordForm.password),
                      ]"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-btn
                      absolute
                      right
                      small
                      color="primary"
                      class="mx-3 rounded-pill"
                      @click="handleSubmitClick()"
                      :loading="loading"
                    >Change Password</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </v-parallax>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { VERIFY_RESET_PASSWORD_LINK, RESET_PASSWORD } from "@/store/_actiontypes";
import validations from "@/utils/validations"
import Logo from "@/components/common/Logo"
import { mdiEye, mdiEyeOff } from '@mdi/js'

export default {
  components: {
    Logo,
  },
  data() {
    return {
      showPassword: false,
      showRePassword: false,
      passwordForm: {
        password: "",
        repassword: "",
      },
      icons: {
        mdiEye,
        mdiEyeOff,
      },
      validLink: false,
      ...validations,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.loader.loading,
      userInfo: (state) => state.account.resetPasswordUserDetails
    }),
  },
  mounted(){
    if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
      const plugin = document.createElement("script");
      plugin.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js?render=${this.$tools.getEnv("CAPTCHA_SITE_KEY")}`
      );
      plugin.async = true;
      document.head.appendChild(plugin);
    }
    this.verifyResetPasswordLink();
  },
  methods: {
    ...mapActions("account", [VERIFY_RESET_PASSWORD_LINK, RESET_PASSWORD]),

    verifyResetPasswordLink(){
      this.VERIFY_RESET_PASSWORD_LINK({
        token: this.$route.params.token
      }).then((res)=>{
        if(res.status===200){
          this.validLink = true;
        }
      },(error) => {
        this.$router.push("/error-404");
      }).catch(err=>{
        this.$router.push("/error-404");
      });
    },
    handleSubmitClick(){
      if (!this.$refs.passwordForm.validate()) return;

      if(this.$tools.getEnv("VUE_APP_CAPTCHA_ENABLED")){
        grecaptcha.execute(this.$tools.getEnv("VUE_APP_CAPTCHA_SITE_KEY"), {
          action: 'reset_password'
        }).then((recaptcha_token) => {
          this.handleSubmit(recaptcha_token);
        });
      } else{
        this.handleSubmit(null);
      }
    },
    handleSubmit(recaptcha_token){
      const { password } = this.passwordForm;
      let token = this.$route.params.token;
      this.RESET_PASSWORD({ token, password, recaptcha_token });
    },
    validatePasswordWithName() {
      if (this.userInfo.name === null){
        return true
      } else {
        return validations.passwordWithName('password', this.userInfo.name, 'Name')
      }
    }
  },
};
</script>

<style></style>
